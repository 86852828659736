import React, {useEffect } from 'react';
import { Button, Col, Container, Image, Row, Form, Accordion } from 'react-bootstrap';

import Slider from "react-slick";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTrendingGames } from '../../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import AllCategory from '../AllCategory';
import ContactUs from '../Contact';
import { add3Dots, setPrimaryColor } from '../commonConst';
import CustomerReview from '../CustomerReview';
import CustomerSlider from '../CustomersSlider';
import { HashLink } from 'react-router-hash-link';

const ManinPage = () => {
    setPrimaryColor()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allGame, setAllGame] = useState();
  
    const { t, i18n } = useTranslation();
       
    useEffect(() => {
        dispatch(getTrendingGames()).then((res) => {
            let temp = res.payload.filter((game) => game.game_script__language ==(i18n.languages[0] === 'sv'?'sv':i18n.languages[0]));
                setAllGame(temp)
        })
       
    }, [i18n.languages[0]])
   
    const trending = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },

        ]
    };
    const gotoPage=(page) =>{
        navigate(page);
    }
    const gotosinglegame =(id,game_type,cat_id) => {
        navigate('/game-details/'+game_type.toLowerCase()+'/'+cat_id+'/'+id+'/true');
    }
    const changeColor =(e) =>{}
    return (
        <>
            <section className='main-banner position-relative back-1'>
                <div className='section-bg-corner  bg-secondary'></div>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div className='main-banner-content mb-md-0 mb-40'>
                                <h1 className='text-md-start text-center font-poppins fw-bold text-capitalize fs-48 mb-md-38 mb-7'>{t('Platform_for')} <br className='d-none d-lg-block' /> {t('online_offline')}</h1>
                                <div className='btn-block text-md-start text-center'>
                                    <Button className='rounded-pill me-md-9 me-4' variant="primary" onClick={()=>gotoPage('/game-list')} >{t('Our_Games')}</Button>
                                    <HashLink to="/#review" className="btn btn-primary rounded-pill">{t('Review')}</HashLink>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='home-banner-img-wrap text-end'>
                                <Image className='position-relative home-banner-img' src="/assets/images/home-banner1.png" alt='home-banner1' width={582} height={434} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <AllCategory refreshPage={changeColor} />

            {/* <div className='back-3'> */}
                <section className='trending-section pb-md-150 pb-80 back-3' id="trending">
                    <Container >
                        <div className='text-center mb-md-70 mb-10'>
                            <p className='fs-18 font-poppins fw-semibold mb-0 text-black-secondary'>Top Selling</p>
                            <h2 className='fs-60 fw-bold text-black-secondary mb-0'>Trending</h2>
                        </div>
                        <div className='overflow-hidden '>
                        <Slider {...trending} className='trending-slider '>
                            {
                                allGame?.map((item, index) => (
                                    // Need to add below class dynamic 
                                    // dark-cityquest , dark-homequest , dark-officequest , dark-schoolquest , dark-halloween
                                
                                <div className={`trending-card mb-md-0 mb-38 ${'dark-' + item.game_script__game_type.toLowerCase()}`}>
                                        <div className='trending-image'>
                                            <img src={item?.game_script__image} className='img-fit' alt="top-selling" width="314" height="457" />
                                        </div>
                                        <div className='trending-content text-center px-6 pt-7'>
                                            <h5 className='font-poppins fs-24 text-white fw-normal'>{item.game_script__game_type}</h5>
                                            <p>{item.game_script__title}</p>
                                            <p className='fs-16 pt-2 border-top'><ReactMarkdown>{add3Dots(item?.game_script__description,300) }</ReactMarkdown></p>
                                            <Button className='rounded-pill' variant="white" onClick={()=>gotosinglegame(item.game_script__id,item.game_script__game_type,item?.game_script__game_category__games_category_id)} >Read More</Button>
                                        </div>
                                    </div>
                                    ))}
                        </Slider>
                    </div>
                    
                    </Container>
                    <div className='back-4'></div>
                </section>
            {/* </div> */}
           

            <section className='why-choose-us-section bg-light-gray' id="how-it-work">
                <Container>
                    <Row className='flex-md-row flex-column-reverse'>
                        <Col md={6} className='px-lg-40 px-5 mt-md-0 mt-11'>
                            <div className='why-choose-us-img-wrap'>
                                <Image src='/assets/images/why-choose-us.png' className='rounded-5' alt='why-choose-us' width={581} height={547}></Image>
                            </div>
                        </Col>
                        <Col md={6} className='ps-lg-11'>
                            <h6 className='text-black-secondary'>{t('About_us')}</h6>
                            <h2 className='fs-60 fw-bold text-black-secondary mb-10'>{t('Why_Choose_us')}</h2>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className='accordion-green'>
                                    <Accordion.Header className='fs-28'>{t('Our_idea_title')}</Accordion.Header>
                                    <Accordion.Body className='fs-20'>
                                    {t('Our_idea_text')}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='accordion-blue'>
                                    <Accordion.Header className='fs-28'>{t('Vision_title')}</Accordion.Header>
                                    <Accordion.Body className='fs-20'>
                                    {t('Vision_text')}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='accordion-orange'>
                                    <Accordion.Header className='fs-28'>{t('Mission_title')}</Accordion.Header>
                                    <Accordion.Body className='fs-20'>
                                    {t('Mission_text')}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>


            <div className='customer-section font-poppins bg-white'>
                <CustomerSlider/>
            </div>

            <section className='bg-light-gray py-50' id="review">
                <CustomerReview/>
            </section>


            <section className='contact-container' id="contact">
              <ContactUs></ContactUs>
            </section>

        </>
    )
}

export default ManinPage;