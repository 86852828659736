import React, { useEffect,useState } from 'react';
import {getGamesCategoryFag} from '../../store/api/course.api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import {Accordion} from "react-bootstrap";
import CategoryBanner from '../shared/CategoryBanner';

export const Faq =() => {
    const { game_type } = useParams();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
        const [allfaq, setAllfaq] = useState();
    useEffect(() => {
        dispatch(getGamesCategoryFag(game_type)).then((res) => {
            setAllfaq(res.payload)
              })
    }, [i18n.languages[0]])

return (
    <div>
    <CategoryBanner game_type={game_type} />
    <div className="ct-container-920 ">
        <div className=" pt-10 p-70 pb-10">
        <h2 className='mb-md-10 mb-9 fs-48 font-poppins fw-bold text-capitalize text-center'><span className='text-primary'>{t('FAQ')}</span>  </h2>

            <div className="tabing-question mb-40">
            <Accordion defaultActiveKey="0" className="mb-40 accordion-faq">
                {
                    allfaq && allfaq.length > 0 && allfaq?.map((item, index) => (
                            <Accordion.Item eventKey={index} className="mb-30 accordion-item-faq" key={index}>
                                <Accordion.Header className='accordion-button-faq'>
                                    {i18n.languages[0] === 'sv' ? item?.question_sw:item?.question}
                                </Accordion.Header>
                                <Accordion.Body >
                                    <p>{i18n.languages[0] === 'sv' ? item?.answer_sw:item?.answer}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                              ))}
                            
                        </Accordion>
            </div>
        </div>
    </div>
</div>
)
}

export default Faq