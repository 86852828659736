import { Container } from 'react-bootstrap';
import { setPrimaryColor } from '../shared/commonConst';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    setPrimaryColor()
    return (
        <>
            <section className='main-banner position-relative'>

                <Container>
                    <div className="pageContainer">
                        <div className="container-fluid container--small container--big__text">
                            <h1 className="h1 fw-semibold">{t('Privacy_Policy')}</h1>
                            <div class="text font-16">
                                <p><strong>Last Edited on: March 10, 2022</strong></p>
                                <h3>Who we are</h3>
                                <p><strong>ARDI GLOBAL LLC</strong>
                                    operates the following websites (hereinafter referred to as the “Service”):
                                </p>
                                <ul>
                                    <li>
                                        <Link to="https://ardi.one/homequest/" target="_blank" rel="noopener noreferrer">https://ardi.one/homequest/</Link>
                                    </li>
                                    <li>
                                        <Link to="https://homequest.se/?__hstc=6405357.d5152913b1bb8e88c1150b74e7671baa.1707149894064.1713453690990.1718737841722.5&amp;__hssc=6405357.9.1718737841722&amp;__hsfp=486857294" target="_blank" rel="noopener noreferrer">https://homequest.se/</Link>
                                    </li>
                                    <li>
                                        <Link to="https://ardi.one/cityquest/" target="_blank" rel="noopener noreferrer">https://ardi.one/cityquest/</Link>
                                    </li>
                                    <li>
                                        <Link to="https://cityquest.se/?__hstc=6405357.d5152913b1bb8e88c1150b74e7671baa.1707149894064.1713453690990.1718737841722.5&amp;__hssc=6405357.9.1718737841722&amp;__hsfp=486857294" target="_blank" rel="noopener noreferrer">https://cityquest.se/</Link>
                                    </li>
                                    <li>
                                        <Link to="https://ardi.one/officequest/" target="_blank" rel="noopener noreferrer">https://ardi.one/officequest/</Link>
                                    </li>
                                    <li>
                                        <Link to="https://officequest.se/?__hstc=6405357.d5152913b1bb8e88c1150b74e7671baa.1707149894064.1713453690990.1718737841722.5&amp;__hssc=6405357.9.1718737841722&amp;__hsfp=486857294" target="_blank" rel="noopener noreferrer">https://officequest.se/</Link>
                                    </li>
                                </ul>
                                <p>which are a part of the common service  <Link to="https://ardi.one/" target="_blank" rel="noopener noreferrer">https://ardi.one/</Link>. Throughout the site, the terms “us”, “we”, “our”, "ARDI" refer to <strong>ARDI GLOBAL LLC</strong>.</p>

                                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                                <p>By using the Service, you agree to the collection and use of information in accordance with this policy.</p>

                                <h3>Information Collection And Use</h3>
                                <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                                <h4>Types of Data Collected</h4>
                                <h4>Personal Data</h4>

                                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>

                                <ul>
                                    <li>First and last name.</li>
                                    <li>Email address.</li>
                                    <li>Phone number.</li>
                                    <li>Cookies and Usage Data.</li>
                                </ul>

                                <h4>Usage Data</h4>

                                <p>We may also collect information about how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                                <h4>Tracking & Cookies Data</h4>

                                <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                                <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                <p>Examples of Cookies we use:</p>

                                <ul>
                                    <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                                    <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
                                    <li>Security Cookies. We use Security Cookies for security purposes.</li>
                                </ul>

                                <h3>Use of Data</h3>
                                <p>ARDI uses the collected data for various purposes:</p>

                                <ul>
                                    <li>To provide and maintain our Service.</li>
                                    <li>To notify you about changes to our Service.</li>
                                    <li>To allow you to participate in interactive features of our Service when you choose to do so.</li>
                                    <li>To provide customer support.</li>
                                    <li>To gather analysis or valuable information so that we can improve our Service.</li>
                                    <li>To monitor the usage of our Service.</li>
                                    <li>To detect, prevent and address technical issues.</li>
                                    <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</li>
                                </ul>

                                <h4>Retention of Data</h4>

                                <p>ARDI will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

                                <p>ARDI will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>

                                <h4>Transfer Of Data</h4>

                                <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>

                                <p>If you are located outside of the EU and choose to provide information to us, please note that we transfer the data, including Personal Data, to the EU and process it there.</p>

                                <p>The data is stored on the servers, located in Frankfurt, Germany and operated by Linode, LLC: <Link to="https://www.linode.com/legal-privacy/" target="_blank" rel="noopener noreferrer">https://www.linode.com/legal-privacy/</Link></p>

                                <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

                                <p>ARDI will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                                <h3>Disclosure Of Data</h3>
                                <h4>Disclosure for Law Enforcement</h4>

                                <p>Under certain circumstances, ARDI may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

                                <h4>Legal Requirements</h4>
                                <p>ARDI may disclose your Personal Data in the good faith belief that such action is necessary to:</p>

                                <ul>
                                    <li>To comply with a legal obligation.</li>
                                    <li>To protect and defend the rights or property of ARDI.</li>
                                    <li>To prevent or investigate possible wrongdoing in connection with the Service.</li>
                                    <li>To protect the personal safety of users of the Service or the public.</li>
                                    <li>To protect against legal liability.</li>
                                </ul>

                                <h3>Security Of Data</h3>

                                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                                
                                <h3>Your Rights</h3>

                                <p>ARDI aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                                <p>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.</p>
                                <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us at hello@ardi.one</p>
                                
                                <h3>Service Providers</h3>

                                <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                                <h4>Administration</h4>
                                <p>We may use third-party Service Providers that helps us manage some administrative work, marketing and sales.</p>
                                
                                <h5>HubSpot</h5>

                                <p>HubSpot offers a full platform of marketing, sales, customer service, and CRM software.</p>
                                <p>For more information please visit the HubSpot Privacy & Terms web page: 
                                    <Link to="https://legal.hubspot.com/privacy-policy?__hstc=6405357.d5152913b1bb8e88c1150b74e7671baa.1707149894064.1713453690990.1718737841722.5&amp;__hssc=6405357.9.1718737841722&amp;__hsfp=486857294" target="_blank" rel="noopener noreferrer">https://legal.hubspot.com/privacy-policy</Link>
                                </p>

                                <h4>Analytics</h4>
                                <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                               
                                <h5>Google Analytics</h5>

                                <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
                                
                                <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
                                
                                <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <Link to="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</Link>
                                </p>
                                
                                <h5>Facebook pixel</h5>
                                
                                <p>Facebook Pixel is a web analytics service offered by Facebook that tracks and reports website traffic. This data is stored in our Facebook Business account. The Facebook pixel tracks conversions that can be attributed to ads on Facebook, Instagram and Audience Network.</p>
                                
                                <p>For more information please visit the Facebook Privacy & Terms web page: <Link to="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">https://www.facebook.com/policy.php</Link></p>
                               
                                <h5>Microsoft Clarity</h5>

                                <p>Microsoft Clarity is a web analytics service that helps us to understand what users are really doing on our websites by creating heatmaps that show us the parts of our websites that are most used.</p>

                                <p>For more information please visit the Microsofts Privacy & Terms web page: <Link to="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" rel="noopener noreferrer">https://privacy.microsoft.com/en-us/privacystatement</Link></p>

                                <h4>Payments</h4>
                               
                                <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>

                                <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>

                                <p>The payment processors we work with are:</p>

                                <h5>Stripe</h5>
                                
                                <p>For more information please visit the Stipe Privacy & Terms web page: <Link to="https://stripe.com/en-se/privacy" target="_blank" rel="noopener noreferrer">https://stripe.com/en-se/privacy</Link></p>
                               
                                <h5>Swish</h5>

                                <p>For more information please visit the Swish Privacy & Terms web page: <Link to="https://www.swish.nu/policies" target="_blank" rel="noopener noreferrer">https://www.swish.nu/policies</Link></p>
                                
                                <h3>Links To Other Sites</h3>

                                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                                
                                <h3>Changes To This Privacy Policy</h3>

                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. Your continued use of the website following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
                                
                                <h3>Consent</h3>

                                <p>By using our website, you consent to the regulations of our privacy policy. If you do not agree to this policy, please do not use our website.&nbsp;</p>
                                
                                <h3>Contact Us</h3>

                                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                                <p><strong>ARDI GLOBAL LLC</strong></p>
                                <p>Email: <Link to="mailto:hello@ardi.one">hello@ardi.one</Link></p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>


        </>
    )
}

export default PrivacyPolicy;