import { toast } from "react-toastify";
import axios from "./httpService";
  const API_URL = axios.Api;
  
  const completeSignup = (body) => {
    return axios.post(API_URL + "core/complete-signup/",body);
  };
  const is_signupCompleted = (body) => {
    return axios.post(API_URL + "core/is-signup/",body);
  };
const authService = {
  completeSignup,
  is_signupCompleted
};

export default authService;
