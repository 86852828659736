import { Button, Form, Image } from "react-bootstrap";
import SvgComponent from "../../Components/SvgComponent";

const SignUp = () => {
    return(
        <div className="authentication-page d-flex">
            <div className="w-50 w-md-full d-flex align-items-center justify-content-center">
                <div className="text-start w-80">
                    <div className="logo-blockwrap mx-auto d-md-none d-block  mb-10 mt-10   ">
                    <SvgComponent width={'100%'} height={'100%'} viewBox='0 0 192 45' SvgName="loginsymbol"  />
                    </div>
                    <div className="block mb-44">
                        <h1>Sign Up</h1>
                        <p>Create Account and Using Fun Sphere</p>
                    </div>
                    <Form>

                <Form.Group className="mb-20" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter your Email Address" />
                </Form.Group>
                <Form.Group className="mb-20" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter your Password" />
                </Form.Group>
                <Form.Group className="mb-20" controlId="password">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter your Password" />
                </Form.Group>
                <div className="text-center pt-sm-5 pt-3 mb-sm-5 mb-3 w-80 m-auto">
                    <Button varint="primary" size="lg" className="radius-36 w-100">SignUp</Button>
                </div>
                </Form>

                <div className="text-center">
                <span className="d-block mb-sm-4 mb-3">or continue with</span>
                <div className="d-inline-flex align-items-center justify-content-center">
                    <Button variant="link" className="social-btn">
                        <Image src="/assets/images/Facebook.svg" alt="Facebook" width={20} height={20} />
                    </Button>
                    <Button variant="link" className="social-btn">
                    <Image src="/assets/images/apple.svg" alt="Apple" width={20} height={20} />
                    </Button>
                    <Button variant="link" className="social-btn">
                    <Image src="/assets/images/google.svg" alt="Google" width={20} height={20} />
                    </Button>
                </div>
               </div>
                </div>
               
            </div>
            <div className="w-50 align-items-center justify-content-center bg-light d-md-flex d-none">
                <div className=" mx-auto">
                <SvgComponent width={'100%'} height={'100%'} viewBox='0 0 192 45' SvgName="loginsymbol"  />
                </div>
            </div>
        </div>



    )
}

export default SignUp;
