import sprite from '../../Images/sprite.svg';
const SvgComponent = ({SvgName, width=24, height=24, viewBox='0 0 24 24', className,style}) => {
    return(
        <svg viewBox={viewBox} width={width} height={height} className={className} style={style}>
        <use 
            xlinkHref={`${sprite}#${SvgName}`} 
            href={`${sprite}#${SvgName}`}>
        </use>
        </svg>
    )
}

export default SvgComponent;