import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en', 
    // we init with resources
    resources: {
   
      sv: {
        translations: {
          'About_us':'Om oss',
          'Sv':'SV',
          'En':'EN',
          'Number_of_episodes':'Antal serier: ',
          'Single_game':'Enskild spel',
          'Episode':'Serie #',
          'Games_we_produce':'Våra spel',
          'Platform_for':'Plattform för toppklass',
          'online_offline':'inomhus och utomhus äventyrsspel',
          'city_quest_title':'Ny typ av underhållning som är en mix av escape room, treasure hunt och geocaching, men i staden.',
          'home_quest_title':'En interaktiv spelupplevelse som man kan njuta av hemma eller digitalt med familj och vänner.',
          'office_quest_title':'Spelet är perfekt för att testa eran samarbetsförmåga och lösa digitala pussel tillsammans. Ni kan spela på kontoret eller dra ett videosamtal om ni jobbar på distans!',
          'Our_idea_text':'Vår affärsidé är att ge våra kunder en enkel och prisvärd tillgång till toppklass on- och offline äventyrsspel på daglig basis. Vi skapar en prenumerationstjänst för gruppaktiviteter som hjälper till att förbättra nivån på social medvetenhet.',
          'Vision_text':'Vår vision är att skapa ett enkelt spelverktyg för umgänge i det verkliga livet i ett samhälle överbelastat med online kommunikation.',
          'Mission_text':'Vår mission är att öka nivån på social kommunikation mellan människor med hjälp av tekniken som vi använder i vardagen.',
          'Our_idea_title':'Affärsidé',
          'Vision_title':'Vision',
          'Mission_title':'Mission',
          'Login':'Logga in',
          'SignUp':'Skapa konto',
          'Our_customer':'Våra kunder',
          'Highly_recommend':'Perfekt i Corona-tider',
          'Highly_recommend_comment':'Spelade OfficeQuest med ett par vänner, väldigt kul! Älskar escape rooms och det här är en grym digital version av det! Perfekt i Corona-tider + att man kan samla ihop ett gäng som inte bor i samma stad!',
          'Highly_recommend_poster':'David Rydén',
          'Excellent_service_and_a_great_quest_experience':'En mycket trevlig kväll',
          'Excellent_service_comment':`Hade en mycket trevlig kväll på stan med några kompisar när vi fick runt och spelade. Kul sätt att uppleva Stockholm på och trots att jag bott här länge så upptäckte man lite nya detaljer. Är ofta i delen av stan där slutet av uppdraget utspelade sig och brukar alltid tänka på när vi körde :)`,
          'Excellent_service_poster':'Jonatan',
          'We_had_a_lot_of_fun_solving_the_puzzles':'Positiv feedback',
          'We_had_a_lot_commnet':`Positiv feedback: Bra story, med nutida koppling och en bra promenadrunda ända bort till Kungsan :).`,
          'We_had_a_lot_poster':'Marie Pierre',
          'Home':'Hem',
          'Our_Games':'Våra spel',
          'Trending':'Trending',
          'How_it_work':'How it work',
          'Contacts':'Kontakta',
          'Creator_Program':'Skaparprogram',
          'Review':'Omdömen',
          'What_Kind_Of':'What Kind Of',
          'Quester_Are_You':'Quester Are You?',
          'Follow_us':'Följ oss',
          'A_digital_board_social':'Vill du kontakta oss via sociala media? Du får jättegärna göra det via en av våra kanaler.',
          'What_others_say':'Vad säger andra',
          'Why_Choose_us':'Why Choose us',
          'Blogs':'Blogs',
          'FAQ':'Vanliga frågor',
          'Terms_And_Conditions':'Allmänna villkor',
          'Privacy_Policy':'Integritetspolicy',
          'Contact_Us':'Kontakta oss',
          'Name':'Namn',
          'Email':'E-post',
          'Message':'Meddelande',
          'Send':'Skicka',
          'Company':'Företag',
          'Legal':'Legal'
      }
      },
      en: {
        translations: {
          'About_us':'About Us',
          'En':'EN',
          'Sv':'SV',
          'Number_of_episodes':'Number of episodes: ',
          'Single_game':'Single game',
          'Episode':'Episode #',
          'Games_we_produce':'Games we produce',
          'Platform_for':'Platform for top-class',
          'online_offline':'online & offline adventure games',
          'city_quest_title':'It is a new type of group activity in town that can be described as a mix of escape game, treasure hunt and geocaching.',
          'home_quest_title':'A digital board game experience that will help to bring friends and family together. It can be easily played at home as well as online via a conference call.',
          'office_quest_title':'This game will help to improve your teamwork while solving puzzles together with your colleagues. You can play in the office or online via conference call.',
          'Our_idea_text':'Our business idea is to provide our customers with easy and affordable access to the top class on & offline adventure games and events on a daily basis. We create a subscription service for group activities which will help to improve the level of social awareness.',
          'Vision_text':'Our vision is to create a simple tool based on group activities for socializing in real life in a society overloaded with online communication.',
          'Mission_text':'Our mission is to increase the level of social interaction among people with the help of the technology we use in everyday life.',
          'Our_idea_title':'Our idea',
          'Vision_title':'Vision',
          'Mission_title':'Mission',
          'Login':'Login',
          'SignUp':'Sign Up',
          'Our_customer':'Our Customers',
          'Highly_recommend':'Highly recommend!',
          'Highly_recommend_comment':'Such good fun! Not only do you get a good story with a mystery, but you also get to challenge your logical side and observational skills. That plus a nice walk around Stockholm makes for a great couple of hours with friends! Highly recommend!',
          'Highly_recommend_poster':'David Axel Dahl',
          'Excellent_service_and_a_great_quest_experience':'Excellent service and a great quest experience',
          'Excellent_service_comment':`We did a murder quest ("See Stockholm and Die") with our company, and had such a great time! Felt like the perfect level of difficulty where everyone could play but it wasn't too easy either, and the customer service was fantastic when we had to adjust a few things. Great experience!`,
          'Excellent_service_poster':'Tove Hernlund',
          'We_had_a_lot_of_fun_solving_the_puzzles':'We had a lot of fun solving the puzzles',
          'We_had_a_lot_commnet':`We've teamed up with our friends to test several games, including "Why do you cry, baby?" & "Mission: ILoveYou". We had a lot of fun solving the puzzles, and the storylines were unique and captivating.`,
          'We_had_a_lot_poster':'Leo',
          'Home':'Home',
          'Our_Games':'Our Games',
          'Review':'Review',
          'Trending':'Trending',
          'How_it_work':'How it work',
          'Contacts':'Contacts',
          'Creator_Program':'Creator Program',
          'What_Kind_Of':'What Kind Of',
          'Quester_Are_You':'Quester Are You?',
          'Follow_us':'Follow us',
          'A_digital_board_social':'We are active on social media! You are more than welcome to write us on Facebook or Instagram if you have any questions.',
          'What_others_say':'What others say',
          'Why_Choose_us':'Why Choose us',
          'Blogs':'Blogs',
          'FAQ':'FAQ',
          'Terms_And_Conditions':'Terms And Conditions',
          'Privacy_Policy':'Privacy Policy',
          'Contact_Us':'Contact Us',
          'Name':'Namn',
          'Email':'Email',
          'Message':'Message',
          'Send':'Send',
          'Company':'Company',
          'Legal':'Legal',
      }
      }
    },
    fallbackLng: "sv",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
