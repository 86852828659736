

import { t } from 'i18next';
import React, { useEffect,useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import SvgComponent from "../../Components/SvgComponent";
import { setPrimaryColor } from '../shared/commonConst';


export const SocialComponent =(props) => {
return (
<section className='media-section mt-1'>
                <Container>
                    <Row className='flex-md-row flex-column-reverse row'>
                        <Col md={5} className='mt-md-0 mt-40'>
                        {props.game_type === '3b66fa62-c0ac-4f76-95c2-9cc71309f41a' &&
                        <img src="/assets/images/our_idea.png" width="690" height="743" />
                        }
                        {props.game_type === 'd6962d38-51a0-4c25-a48c-e604a0cdc178' &&
                        <img src="/assets/images/our_mission.png" width="690" height="743" />
                        }
                        {props.game_type === 'fdb73786-fbae-43fa-8089-34777446d527' &&
                        <img src="/assets/images/our_vision.png" width="690" height="743" />
                        }
                        {props.game_type === '' &&
                        <img src="/assets/images/our_mission.png" width="690" height="743" />
                        }
                        
                        </Col>
                        <Col md={7}>
                            <h2 className='fs-46 fw-medium text-black-ternary'>{t('Follow_us')}</h2>
                            <p className='fs-20 fw-normal text-black-ternary mb-11'>{t('A_digital_board_social')}</p>
                            <ul className="social-links social-links-default mt-3">
                                <li><a href="https://www.instagram.com/ardi.global/" target="_blank" title="Instagram" rel="noopener noreferrer" className="btn btn-primary text-primary is-circle mx-1"><SvgComponent width={36} height={36} viewBox='0 0 48 48' SvgName="instagram" /></a></li>
                                <li><a href="https://www.facebook.com/ARDI.Global/" target="_blank" title="Facebook" rel="noopener noreferrer" className="btn btn-primary text-primary is-circle mx-1"><SvgComponent width={36} height={36} viewBox='0 0 48 48' SvgName="facebook" /></a></li>
                                <li><a href="https://www.linkedin.com/company/14790324" target="_blank" title="LinkedIn" rel="noopener noreferrer" className="btn btn-primary text-primary is-circle mx-1"><SvgComponent width={36} height={36} viewBox='0 0 48 48' SvgName="linkedin" /></a></li>
                                <li><a href="https://se.trustpilot.com/review/ardi.one" target="_blank" title="Trustpilot" rel="noopener noreferrer" className="btn btn-primary text-primary is-circle mx-1"> <SvgComponent width={36} height={36} viewBox='0 0 48 48' SvgName="trustpilot"></SvgComponent></a></li>
                                <li><a href="https://www.tripadvisor.com/Attraction_Review-g189852-d17344395-Reviews-ARDI-Stockholm.html" target="_blank" title="Tripadvisor" rel="noopener noreferrer" className="btn btn-primary text-primary is-circle mx-1"><SvgComponent width={36} height={36} viewBox='0 0 48 48' SvgName="tripadvisor" /></a></li>
                                <li><a href="https://g.page/cityquest_ardi" target="_blank" title="Google" rel="noopener noreferrer" className="btn btn-primary text-primary is-circle mx-1"> <SvgComponent width={36} height={36} viewBox='0 0 48 48' SvgName="google" /></a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>


)
}

export default SocialComponent