import React, { useEffect } from 'react';
import {  Col, Container, Row, Image } from 'react-bootstrap';
import { hexToRgba } from '../shared/commonConst';


import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGamesCategory } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SvgComponent from "../../Components/SvgComponent";

const AllCategory = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        dispatch(getGamesCategory()).then((res) => {
            setAllCategory(res.payload)
        })

    }, [i18n.languages[0]])


    const gotocat = (id, name) => {
        navigate(`/detail/${name}/` + id);
        props.refreshPage(id)
    }
    
    return (
        <>
            <section className='game-produce-section'>
                <Container className='bg-white'>
                    <div className='d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-center mb-8'>
                        <h2 className='mb-md-10 mb-9 fs-48 font-poppins fw-bold text-capitalize text-center'><span className='text-primary'>{t('What_Kind_Of')}</span> <br className='d-none d-lg-block' /> {t('Quester_Are_You')} </h2>
                    </div>

                    <div className='sorting-btns'>
                        <div className='sort-btn active'>
                             I want to go on an adventure!
                        </div>
                        <div className='sort-btn'>
                            solving scary mysteries! <br /> 
                            from the safety of my couch.
                        </div>
                        <div className='sort-btn'>
                            I want all my colleagues to take part of my <br />  awesome escape-room skills
                        </div>
                    </div>
                </Container>

                <div className='back-2'>
                    <Container className='bg-white'>
                        <Row className='g-xxl-11 g-7'>
                            {
                                allCategory && allCategory.length > 0 && allCategory?.map((item, index) => (
                                    <Col lg={3} md={6} className='mb-3'>
                                        <div className='h-100 d-flex flex-column cursor-pointer' onClick={() => gotocat(item.games_category_id,item.name.toLowerCase())}>
                                            <div className={`top-game-boxicon  flex-shrink-0 d-flex align-items-center `} style={{background:hexToRgba(item.color, 0.1),border: `1px solid ${hexToRgba(item.color, 0.3)} `}} >
                                                <SvgComponent width={20} height={26} viewBox='0 0 37 46' SvgName="appsvg"  style={{color:item.color}} />
                                                <h6 className='ms-2 mb-0 mt-0 fs-20'>{item.name}</h6>
                                            </div>
                                            <div className={`game-box h-100  `} style={{background:hexToRgba(item.color, 0.1),border: `1px solid ${hexToRgba(item.color, 0.3)} `}}>

                                                <div className='game-box-img'>
                                                    <Image src={item?.image} className='img-fit' alt='ardi-game'></Image>
                                                </div>
                                                <div className='p-7'>
                                                    <h3 className='fs-32 text-black-ternary mb-1'>{item.name}</h3>
                                                    <p className='fs-18 text-black-ternary mb-0'>{i18n.languages[0] === 'sv'?item.title_sw:item.title} {i18n.languages[0] === 'sv' ? item.subtitle_sw:item.subtitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                        </Row> 
                    </Container>
                </div>                   
            </section>

        </>
    )
}

export default AllCategory;