import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {toast} from 'react-toastify';
import AuthService from "../../services/auth.service";

const user = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):[] ;


export const login = createAsyncThunk(
  "auth/login",
  async (body, thunkAPI) => {
    try {
      const response = await AuthService.completeSignup(body);
      localStorage.setItem("user", JSON.stringify(response.data.data) );
      return { user: response.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        toast.error(message);
      
      return thunkAPI.rejectWithValue();
    }
  }
);
export const is_signupCompleted = createAsyncThunk(
  "signup/get",
  async (body,thunkAPI) => {
    try {
      const response = await AuthService.is_signupCompleted(body);
      localStorage.setItem("user", JSON.stringify(response.data.data) );
    
    //  return response.data;
      return { user: response.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});





const wlogin= user?true:false;
const initialState = user ? { isLoggedIn: wlogin, user } : { isLoggedIn: wlogin, user: null };



const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
   
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [is_signupCompleted.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    }
  },
  reducers: {
    updateSubscription: (state, action) => {
      state.user.subscription.stripe_subscription_id = action.payload;
      let user = JSON.parse(localStorage.getItem('user'));
      user.subscription.stripe_subscription_id = action.payload
      localStorage.setItem("user",JSON.stringify(user))
    },
  }
});

const { reducer,actions } = authSlice;
export const { updateSubscription } = actions
export default reducer;
