import React, { useEffect } from 'react';
import {  Container, Image } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import Slider from "react-slick";

const CustomerSlider = () => {
    const { t, i18n } = useTranslation();
    const logoSlider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
        ]
    };
    return (
        <>
           <Container>
                    <h2 className='mb-2 fs-24 fw-medium text-center font-poppins mb-4'>{t('Our_customer')}</h2>

                    <Slider {...logoSlider} className='logos-block logo-slider'>
                    <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/Microsoft_uid_627a22aa084f1.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/HM_uid_627a3e9db9f5b.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/Lnsfrskringar_uid_627a22dc0d441.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/Myconic_uid_627a3e79a1864.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/Spotify_uid_627a22f085f7c.png" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/Telenor_uid_627a22c80ede2.png" alt="" />
                        </div>

                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/sbab_uid_627a22d38d0dd.svg" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/bilia_uid_627a22e6db563.svg" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/epiroc_uid_627a3e6901145.svg" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/Haninge_uid_627a2308667e3.svg" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/ikea_uid_627a3e8570565.svg" alt="" />
                        </div>
                        <div className='logo-box'>
                            <Image src="/assets/images/ardiclient/sollentuna-kommun_uid_627a22fdeb0fc.svg" alt="" />
                        </div>
                    </Slider>

                </Container>

        </>
    )
}

export default CustomerSlider;