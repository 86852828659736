import React, { useEffect } from 'react';
import {  Container, Image } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';


import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoryreviews } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";

const CustomerReview = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allReview, setAllCategoryReview] = useState();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        dispatch(getCategoryreviews()).then((res) => {
            setAllCategoryReview(res.payload)
        })

    }, [i18n.languages[0]])


   
    const testimonialSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    autoplay: true,
                    arrows: false,
                    infinite: true,
                }
            },
        ]
    };
    return (
        <>
           <Container>
                    <div className='testimonial-sliderwrapper'>
                        <div className="row">
                            <div className="col-rs-10 offset-rs-1">
                                <h2 class="font-poppins fw-semibold fs-40 mb-1">{t('What_others_say')}</h2>
                                <p className='fs-20 font-poppins fw-normal text-gray mb-30'></p>
                                <Slider {...testimonialSlider} className=''>
                                {
                            allReview?.map((item, index) => (
                                    <div className="testimonial-slide ">
                                        <div className='testimonial-img'>
                                            <Image src={item.icon} className='img-fit' alt='testimonial'></Image>
                                        </div>
                                        {/* <h5 class="text-primary">{t('Highly_recommend')}</h5> */}
                                        <div className='testimonial-content'>
                                        
                                            <p className='text-gray fs-20 mb-0'>{i18n.languages[0] === 'sv'?item.title_sw:item.title}</p>
                                            <Rating className='mb-3' readonly={true} size={25} initialValue={item?.star} />
                                            <p className='text-gray fs-16 mb-20'>{i18n.languages[0] === 'sv'?item.subtitle_sw:item.subtitle}</p>
                                            <div className='testimonial-footer align-items-center'>
                                                <div className='block'>
                                                    <h6 className='mb-1 fs-24 text-black-ternary mb-0'>{item?.reviewer_name}</h6>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     ))}

                                </Slider>
                            </div>
                        </div>
                    </div>
                </Container>

        </>
    )
}

export default CustomerReview;