

export function setPrimaryColor(color) {
  document.documentElement.style.setProperty('--primary-color', color ?color:'#0077FF')
  document.documentElement.style.setProperty('--bs-primary', color ?color:'#0077FF')

  //document.documentElement.style.setProperty('--bs-btn-hover-bg', color ?color:'#0077FF')
 // document.documentElement.style.setProperty('--bs-btn-active-bg', color ?color:'#0077FF')
  document.documentElement.style.setProperty('--bs-btn-hover-border-color', color ?color:'#0077FF')
  document.documentElement.style.setProperty('--bs-btn-active-border-color', color ?color:'#0077FF')
}


export function hexToRgba(hex, opacity) {
  // Ensure the hex code starts with a #
  if (hex?.charAt(0) === '#') {
      hex = hex.slice(1);
  }

  // Check if the hex code is shorthand (3 characters) and convert to full form (6 characters)
  if (hex?.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
  }

  // Parse the r, g, b values
  const r = parseInt(hex?.slice(0, 2), 16);
  const g = parseInt(hex?.slice(2, 4), 16);
  const b = parseInt(hex?.slice(4, 6), 16);

  // Ensure the opacity is between 0 and 1
  if (opacity < 0) {
      opacity = 0;
  } else if (opacity > 1) {
      opacity = 1;
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
export const add3Dots = (string, limit) => {
  if (string) {
    var dots = "...";
    if (string?.length > limit) {
      string = string.substring(0, limit) + dots;
    }
  }
  return string != "null" ? string : "";
};