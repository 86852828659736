import React, { useEffect } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGamesCategoryDetail } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {setPrimaryColor} from '../shared/commonConst';

const CategoryBanner = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [catDetail, setCatDetail] = useState();
    const { t, i18n } = useTranslation();
   
    useEffect(() => {
        dispatch(getGamesCategoryDetail(props.game_type)).then((res) => {
            setCatDetail(res.payload)
            setPrimaryColor(res?.payload?.color);
              })

    }, [i18n.languages[0],props.game_type])


    const gotoPage = (page) => {
        navigate(page);
    }
    
    return (
        <>
            <section className='main-banner position-relative'>
                <div className='section-bg-corner'></div>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div className='main-banner-content mb-md-0 mb-40'>
                                <h1 className='text-md-start text-center font-poppins fw-bold text-capitalize fs-48 mb-3'>{catDetail?.title}</h1>
                                <p className='fs-28 lh-sm mb-8 text-md-start text-center '>{catDetail?.subtitle}.</p>
                                <div className='btn-block text-md-start text-center '>
                                    <Button className='rounded-pill me-md-9 me-4' variant="primary" onClick={() => gotoPage('/game-list')}>{t('Our_Games')}</Button>
                                   <HashLink to="/#review" className="btn btn-primary rounded-pill">{t('Review')}</HashLink>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='home-banner-img-wrap tex-end'>
                                <iframe className='position-relative home-banner-img rounded-3' width="100%" height="315" src={catDetail?.video_link} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default CategoryBanner;