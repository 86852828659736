import { Button, Form, Image } from "react-bootstrap";
import SvgComponent from "../../Components/SvgComponent";
import { useNavigate,Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {login,is_signupCompleted} from '../../store/api/auth.api'
import { useState } from "react";

const Login = () => {
    const {  error, user,getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showOrganization,setShowOrganization] = useState(false);
    const [value, setValue] = useState({ first_name: "", last_name: "",usertype:"Normal",organization:"" });
    let token ="";
    const gotoHome = () =>{
       // navigate('/');
    }
    useEffect(() => {
        getTickets();
      }, []);
      async function getTickets() {
         token =  await getAccessTokenSilently();
    
      }

      useEffect(() => {
        
        if(user) {
            var data = {
                'email':user.email,
             }
             dispatch(is_signupCompleted(data))
             .unwrap()
             .then((res) => {
               if(res.user.is_signup_completed) {
                if(res.user.is_organization_user) {
                    if(res.user.is_employee) {
                        navigate('/')
                    } else {
                        navigate('/admin')
                    }
                } else {
                    navigate('/')
                }
                
               }
             })
             .catch((error) => {
               // toast.error(error.message)
             });
        }
      }, [user]);
const handleChange = (e) => {
    setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    if(e.target.name === 'usertype' && e.target.value === 'Organization' ) {
        setShowOrganization(true);
      } else if (e.target.name === 'usertype' && e.target.value === 'Normal') {
        setShowOrganization(false);
        
      }
}

const continueToNewPage = () =>{
    let data = {
        "first_name": value.first_name,
        "last_name": value.last_name,
        "email": user.email,
        "organization": value.organization,
        "token":token
    }
    dispatch(login(data))
    .unwrap()
    .then((res) => {
     
      if(res.user.is_signup_completed) {
        if(res.user.is_organization_user) {
            if(res.user.is_employee) {
                navigate('/')
            } else {
                navigate('/admin')
            }
        } else {
            navigate('/')
        }
       
       
      }
    })
    .catch((error) => {
      // toast.error(error.message)
    });

}
      
    return(
        <div className="authentication-page d-flex">
            <div className="w-50 w-md-full d-flex align-items-center justify-content-center">
                <div className="text-start w-80">
                    <div className="logo-blockwrap mx-auto d-md-none d-block  mb-10  mt-10 ">
                    <SvgComponent width={'100%'} height={'100%'} viewBox='0 0 192 45' SvgName="loginsymbol"  />
                    </div>
                    <div className="login-blocks mb-44">
                        <h1>Complete your signup</h1>
                        <div className="login-btn">
                            <ul>
                                <li>
                                    <a  className={value.usertype === 'Normal'?'active':''}>
                                        <Form.Check type="radio" id={`Normal`} >
                                            <Form.Check.Input className="hiddenRadio" type="radio" name="usertype" value={'Normal'} onChange={handleChange} checked={value.usertype === 'Normal'} />
                                            <Form.Check.Label>Normal</Form.Check.Label>
                                        </Form.Check>
                                    </a>
                                </li>
                                <li>
                                    <a  className={value.usertype === 'Organization'?'active':''}>
                                        <Form.Check type="radio" id={`Organization`} >
                                            <Form.Check.Input className="hiddenRadio" type="radio" name="usertype" value={'Organization'} onChange={handleChange} checked={value.usertype === 'Organization'}  />
                                            <Form.Check.Label>Organization</Form.Check.Label>
                                        </Form.Check>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <p>Select organization if you have a company or school and you want to add more users</p>
                    </div>
                    <Form>

                <Form.Group className="mb-20" controlId="formFirstname">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control name="first_name" type="text" placeholder="Enter first name" value={value.first_name} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-20" controlId="formLastname">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control name="last_name" type="text" placeholder="Enter last name" value={value.last_name} onChange={handleChange} />
                </Form.Group>

                <Form.Group className="mb-20" controlId="formLastname">
                    <Form.Label>Email</Form.Label>
                    <Form.Control name="email" type="text"  value={user?.email}  readOnly={true} />
                </Form.Group>
                {value.usertype === 'Organization' &&
                <Form.Group className="mb-20" controlId="Organization">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control type="text" name="organization" placeholder="Enter your Organization Name" value={value.organization} onChange={handleChange} />
                </Form.Group>
                }
                <div className="text-center pt-sm-5 pt-3 mb-sm-5 mb-3 w-80 m-auto">
                    <Button varint="primary" size="lg" type="button" className="radius-36 w-100" onClick={() => continueToNewPage()}>Complete now</Button>
                </div>
                </Form>

               
                </div>
               
            </div>
            <div className="w-50 align-items-center justify-content-center bg-light d-md-flex d-none">
                <div className=" mx-auto" onClick={()=>gotoHome()} >
                <SvgComponent width={'100%'} height={'100%'} viewBox='0 0 192 45' SvgName="loginsymbol"  />
                </div>
            </div>
        </div>
    )
}

export default Login;
