import {BrowserRouter,Route,Routes} from 'react-router-dom';
import store from "../store";
import { Provider } from "react-redux";
import Home from '../pages/shared/home/home';
import HomeNew from '../pages/shared/home/home-new';
import GameNew from '../pages/user/game-new';
import GameDetailNew from '../pages/user/game-details-new';
import Faq from "../pages/shared/Faq"
import { userRoutes } from './userRoutes/user_route';
import { adminRoute } from './adminRoutes/admin_route';
import { UserLayout } from "../../src/pages/shared/layouts/UserLayout";
import GameList from "../pages/user/GameList";
import ScrollToTop from "../pages/shared/ScrollToTop";
import CreatorProgram from '../pages/user/CreatorProgram';
import PrivacyPolicy from '../pages/shared/PrivacyPolicy';
import TermsAndConditions from '../pages/shared/TermsAndConditions';

export const MainRoutes = () => {
return (
    <Provider store={store}>
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
        <Route path='/' element={<UserLayout />} >
        <Route path='/' element = {<HomeNew />} />
        <Route path='/home' element = {<HomeNew />} />
        <Route path='/detail/:name/:game_type' element = {<GameNew />} />
        <Route path='/game-details/:name/:game_type/:series/:is_single' element = {<GameDetailNew />} />
        <Route path='/game-list' element ={<GameList />} />
        <Route path='/faq/:game_type' element ={<Faq />} />
        <Route path='/creator-program' element = {<CreatorProgram />} />
        <Route path='/privacy-policy' element ={<PrivacyPolicy />} />
        <Route path='/terms-and-conditions' element ={<TermsAndConditions />} />
        
        </Route>
        {/* {userRoutes}
        {adminRoute} */}
    </Routes>
    
    </BrowserRouter>
    </Provider>
)
}