import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./api/auth.api";
import gamesReducer from "./api/course.api";
import cartReducer from "./api/cart"
const reducer = {
  auth: authReducer,
  games: gamesReducer,
  cart: cartReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
