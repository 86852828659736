import React, { useRef, useEffect } from 'react';
import { Button, Col, Container, Image, Row, Form, Card, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGamesCategoryDetail, getCategorywiseGames } from '../../store/api/course.api';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import AllCategory from '../shared/AllCategory';
import {add3Dots } from '../shared/commonConst';
import ReactMarkdown from 'react-markdown';
import CategoryBanner from '../shared/CategoryBanner';
import ContactUs from '../shared/Contact';
import CustomerReview from '../shared/CustomerReview';
import SocialComponent from '../shared/SocialComponent';

const GameNew = () => {
    const dispatch = useDispatch();
    const _ = require('lodash');
    const [allGame, setAllGame] = useState();
    const [allSingleGame, setAllSingleGame] = useState([]);
    const [catDetail, setCatDetail] = useState();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { game_type } = useParams();
    const [type,setNewType] = useState(game_type)
    let imagename = '';
  
    useEffect(() => {
        dispatch(getCategorywiseGames(game_type)).then((res) => {
            //   setAllGame(res.payload.filter((game) => game.game_type === game_type && game.language == (i18n.languages[0] === 'sv'?'sv':i18n.languages[0])));
            let temp = res.payload.filter((game) => game.games_category_id === game_type && game.language == (i18n.languages[0] === 'sv' ? 'sv' : i18n.languages[0]) && game.series !== null);
            let tempwithnull = res.payload.filter((game) => game.games_category_id === game_type && game.language == (i18n.languages[0] === 'sv' ? 'sv' : i18n.languages[0]) && game.series === null);
            setAllGame(_.groupBy(temp, 'series'))
            setAllSingleGame(tempwithnull)
            setNewType(game_type)
        })
        dispatch(getGamesCategoryDetail(game_type)).then((res) => {
            setCatDetail(res.payload);
        })
    }, [i18n.languages[0], game_type]);

    const gotosinglegame = (id, name, flag) => {
        navigate('/game-details/' + name + '/' + game_type + '/' + id + '/' + flag);
    }
    const gotoFaq = (id) => {
        navigate('/faq/' + game_type);
    }

    const changeColor =(e) =>{
       setNewType(e)
    }
    
    useEffect(() => {
        // window.location.reload()
    }, []);
    return (
        <>
            <CategoryBanner game_type={type} />
            <section className='homequest-section bg-light-gray py-md-80 py-40'>
                <Container>
                    <Row className='flex-md-row flex-column-reverse row'>
                        <Col md={6} className='mt-md-0 mt-11'>
                            <div className='homequest-img-wrap'>
                                <Image src={catDetail?.image ? catDetail?.image : imagename} alt='homequest' width={613} height={500}></Image>
                            </div>
                        </Col>
                        <Col md={6} className='ps-lg-40'>
                            <h4 className='fs-36 fw-bold text-black font-poppins pb-3 mb-9 section-title d-inline-block border-primary'>What is <br className='d-none d-lg-block' /> {catDetail?.name}</h4>
                            <p className='font-poppins fw-medium mb-2 fs-16 text-black-secondary lh-sm'>{catDetail?.description}</p>

                            <Button className='rounded-pill px-38 mt-7' variant='primary' onClick={gotoFaq}>FAQ</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="game-info-cards py-60">
                <Container>
                    <Row className='justify-content-center g-11'>
                        {
                            allGame && Object.keys(allGame).map(template_name => (
                                <Col lg={4} sm={6}>

                                    <Card className='h-100'>
                                        <div className="height-220">
                                            {allGame[template_name][0]?.games_category_title === "CityQuest" &&
                                                <span className="card-text-header">{allGame[template_name][0]?.meeting_point_text}</span>
                                            }
                                            <Card.Img variant="top" src={allGame[template_name][0]?.image} className="w-100 h-100 object-fit" />
                                        </div>
                                        <Card.Body className="text-start font-16 text-black fw-medium">
                                            <div className="d-xl-flex align-items-center justify-content-between mb-2">
                                                <Card.Title className="h5 fw-800 mb-1">{allGame[template_name][0]?.title}</Card.Title>
                                                <Badge bg="secondary" className="flex-shrink-0 font-secondary fw-normal minw-90 rounded-pill font-14 flex-shrink-0 mb-1">
                                                    {allGame[template_name][0].episode ? t('Number_of_episodes') + allGame[template_name].length : t('Single_game')}
                                                </Badge>
                                            </div>
                                            <Card.Text>
                                                <p className="font-poppins text-black fw-medium fs-16">{allGame[template_name][0]?.min_players_per_team + '-' + allGame[template_name][0]?.max_players_per_team} players</p>
                                                <ReactMarkdown>{add3Dots(allGame[template_name][0]?.description, 300)}</ReactMarkdown>
                                            </Card.Text>

                                            <Button variant="link" className="font-16 fw-800 text-decoration-none text-primary" onClick={() => gotosinglegame(allGame[template_name][0].series, allGame[template_name][0].games_category_title?.toLowerCase(), false)}>Learn More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        {
                            allSingleGame && allSingleGame.map(item => (
                                <Col lg={4} sm={6}>
                                    <Card className='h-100'>
                                        <div className="height-220">
                                            {item.games_category_title === "CityQuest" &&
                                                <span className="card-text-header">{item?.meeting_point_text}</span>
                                            }
                                            <Card.Img variant="top" src={item?.image} className="w-100 h-100 object-fit" />
                                        </div>
                                        <Card.Body className="text-start font-16 text-black fw-medium">
                                            <div className="d-xl-flex align-items-center justify-content-between mb-2">
                                                <Card.Title className="h5 fw-800 mb-1">{item?.title}</Card.Title>
                                                <Badge bg="secondary" className="flex-shrink-0 font-secondary fw-normal minw-90 rounded-pill font-14 flex-shrink-0 mb-1">Single game</Badge>
                                            </div>
                                            <Card.Text>
                                                <p className="font-poppins text-black fw-medium fs-16">{item.min_players_per_team + '-' + item.max_players_per_team} players</p>
                                                <ReactMarkdown>{add3Dots(item?.description, 300)}</ReactMarkdown>
                                            </Card.Text>
                                            <Button variant="link" className="font-16 fw-800 text-decoration-none text-primary" onClick={() => gotosinglegame(item.id, item.games_category_title?.toLowerCase(), true)}>Learn More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </Container>
            </section>
            <AllCategory refreshPage={changeColor} />
            <section className='bg-light-gray py-50'>
                <Container>
                    <div className='testimonial-sliderwrapper'>
                    <CustomerReview/>
                    </div>
                </Container>
            </section>
            <SocialComponent game_type={type} />

            <section className='contact-container' id="contact">
              <ContactUs></ContactUs>
            </section>
        </>
    )
}

export default GameNew;