import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/user.service";
import { toast } from 'react-toastify';

export const getGames = createAsyncThunk(
  "games/get",
  async (thunkAPI) => {
    try {
      const response = await userService.getPublicContent();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getGamesCategory = createAsyncThunk(
  "games/get",
  async (thunkAPI) => {
    try {
      const response = await userService.getGamesCategory();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getGamesCategoryDetail = createAsyncThunk(
  "games/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getGamesCategoryDetail(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getGamesCategoryFag = createAsyncThunk(
  "games/faq",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getGamesCategoryFag(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCategorywiseGames = createAsyncThunk(
  "games/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getCategorywiseGames(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getGamesOfOrganization = createAsyncThunk(
  "games/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getGamesOfOrganization(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addGame = createAsyncThunk(
  "add/game",
  async (body,thunkAPI) => {
    try {
      const response = await userService.addGame(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);

export const editGame = createAsyncThunk(
  "edit/game",
  async (body,thunkAPI) => {
    try {
      const response = await userService.editGame(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getUserDetails = createAsyncThunk(
  "get/user",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getUserDetails(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);


export const addTips = createAsyncThunk(
  "add/tips",
  async (body,thunkAPI) => {
    try {
      const response = await userService.addTips(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addQuestion = createAsyncThunk(
  "add/question",
  async (body,thunkAPI) => {
    try {
      const response = await userService.addQuestion(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getSingleGames = createAsyncThunk(
  "games/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSingleGames(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleGamesAttachment = createAsyncThunk(
  "games/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSingleGamesAttachment(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleGamesTip = createAsyncThunk(
  "games/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSingleGamesTip(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleGamesFinalQuestion = createAsyncThunk(
  "games/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getSingleGamesFinalQuestion(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAllGames = createAsyncThunk(
  "games/get",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getAllGames(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getTrendingGames = createAsyncThunk(
  "games/tranding",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getTrendingGames();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addContact = createAsyncThunk(
  "add/contact",
  async (body,thunkAPI) => {
    try {
      const response = await userService.contactus(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCategoryreviews = createAsyncThunk(
  "games/reviews",
  async (body, thunkAPI) => {
    try {
      const response = await userService.getCategoryreviews();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
     
      return thunkAPI.rejectWithValue();
    }
  }
);
// Store is setting data here
const initialState = {}
const gamesSlice = createSlice({
  name: "games",
  initialState,
  extraReducers: {
    [getGames.fulfilled]: (state, action) => {
      state.allgames = action.payload;
    },
    [getGames.rejected]: (state, action) => {
      state.allgames = null;
    },
  },
});


const { reducer } = gamesSlice;
export default reducer;
